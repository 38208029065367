<template>
  <div>
    <frame>
      <!-- 头部添加优惠券一行 -->
      <div class="head">
        <div class="left">
          <b class="left_tit">平台信息</b>
        </div>
        <div class="right">
          <el-button class="refresh" @click="refresh()" icon="el-icon-refresh"
            >刷新</el-button
          >
        </div>
      </div>
      <!-- 添加广告文字一行 -->
      <div class="screen">
        <div class="screen_left">
          <i class="el-icon-search"></i>
          平台信息设置
        </div>
      </div>

      <!-- 添加表单主题部分 -->
      <div class="center">
        <el-form
          ref="ruleForm"
          :model="ruleForm"
          label-width="100px"
          :rules="ruleValidate"
        >
          <el-form-item label="适用商户:" v-show="merchantShow">
            <el-select
              v-model="ruleForm.business_id"
              placeholder="请选择商户"
              @change="changeMerchant(ruleForm.business_id)"
              style="width: 300px"
            >
              <el-option
                v-for="item in ruleForm.businessList"
                :key="item.business_id"
                :label="item.business_name"
                :value="item.business_id"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="适用门店:" v-show="storeShow">
            <el-select
              v-model="ruleForm.storeid"
              placeholder="请先选择门店"
              style="width: 300px"
            >
              <el-option
                v-for="item in ruleForm.storeList"
                :key="item.s_name"
                :label="item.s_name"
                :value="item.storeid"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="模板名称：" prop="template_name">
            <el-input
              v-model="ruleForm.template_name"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="计价方式：" prop="pricing_method">
            <el-radio-group v-model="ruleForm.pricing_method">
              <el-radio label="3">按重量计算</el-radio>
              <el-radio label="4">按商品件数计算</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="数量：" prop="number">
            <el-input v-model="ruleForm.number" style="width: 300px"></el-input>
          </el-form-item>

          <el-form-item label="起步价：" prop="start_price">
            <el-input
              v-model="ruleForm.start_price"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="增加数量：" prop="increase_quantity">
            <el-input
              v-model="ruleForm.increase_quantity"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="加价：" prop="increase_price	">
            <el-input
              v-model.number="ruleForm.increase_price"
              style="width: 300px"
            ></el-input>
          </el-form-item>

          <el-form-item label="是否启用：" prop="is_open">
            <el-radio-group v-model="ruleForm.is_open">
              <el-radio label="0">上线</el-radio>
              <el-radio label="1">下线</el-radio>
            </el-radio-group>
          </el-form-item>

          <el-form-item label="目的地：">
            <el-select
              v-model="ruleForm.province.REGION_NAME"
              placeholder="省"
              @change="province($event)"
              style="width: 150px"
            >
              <el-option
                v-for="item in ruleForm.province.provinceList"
                :key="item.REGION_ID"
                :label="item.REGION_NAME"
                :value="item.REGION_ID"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="ruleForm.city.REGION_NAME"
              placeholder="市"
              @change="city($event)"
              style="width: 150px"
            >
              <el-option
                v-for="item in ruleForm.city.cityList"
                :key="item.REGION_ID"
                :label="item.REGION_NAME"
                :value="item.REGION_ID"
              >
              </el-option>
            </el-select>

            <el-select
              v-model="ruleForm.county.REGION_NAME"
              placeholder="区"
              @change="county($event)"
              style="width: 150px"
            >
              <el-option
                v-for="item in ruleForm.county.countyList"
                :key="item.REGION_ID"
                :label="item.REGION_NAME"
                :value="item.REGION_ID"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button
              @click="submitForm('ruleForm')"
              type="primary"
              style="width: 120px"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </frame>
  </div>
</template>

<script>
import frame from "../../public/Frame.vue";
export default {
  data() {
    return {
      merchantShow: false, //不同权限下的显示隐藏
      storeShow: false,
      //提交的表单数据
      ruleForm: {
        businessList: [{}], //商户列表
        business_id: "", //选中的商户ID
        storeList: [{}], //门店列表
        storeid: "", //选中的门店ID
        template_name: "", //模板名称
        pricing_method: "", //计价方式
        number: "", //数量
        start_price: "", //	起步价
        increase_quantity: "", //	增加数量
        increase_price: "", //	加价
        is_open: "", //是否禁用0启用1未启用
        freightid: "",
        province: {
          provinceList: [{}], //省
          REGION_ID: "", //省份id
          REGION_NAME: "", //省份名称
        },
        city: {
          cityList: [{}], //市
          REGION_ID: "", //市区id
          REGION_NAME: "", //市区名称
        },
        county: {
          countyList: [{}], //区
          REGION_ID: "", //区id
          REGION_NAME: "", //区县名称
        },
      },
      //表单验证数据
      ruleValidate: {
        platform_name: [
          {
            required: true,
            message: "请输入平台名称", //账号
            trigger: "blur",
          },
        ],
        platform_title: [
          {
            required: true,
            message: "请输入店铺平台标题", //店铺
            trigger: "blur",
          },
        ],
        platform_desc: [
          {
            required: true,
            message: "请输入平台描述", //地址
            trigger: "blur",
          },
        ],
        key_word: [
          {
            required: true,
            message: "请输入关键词", //手机号
            trigger: "blur",
          },
        ],
        hot_line: [
          { required: true, message: "客服热线不能为空" },
          { type: "number", message: "客服热线必须为数字值" },
        ],
        service_email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  methods: {
    //选择省份请求城市列表
    province(e) {
      let obj = {};
      obj = this.ruleForm.province.provinceList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.REGION_ID === e; //筛选出匹配数据
      });
      this.ruleForm.province.REGION_NAME = obj.REGION_NAME;
      this.$request
        .regionSel({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          region_id: obj.REGION_ID,
        })
        .then((res) => {
          this.ruleForm.city.cityList = res.data;
        });
    },

    //选择城市请求区县列表
    city(e) {
      let obj = {};
      obj = this.ruleForm.city.cityList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.REGION_ID === e; //筛选出匹配数据
      });
      console.log(obj.REGION_NAME); //获取的 name
      console.log(obj.REGION_ID); //获取的 id
      this.ruleForm.city.REGION_NAME = obj.REGION_NAME;
      this.$request
        .regionSel({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          region_id: obj.REGION_ID,
        })
        .then((res) => {
          console.log(res);
          this.ruleForm.county.countyList = res.data;
        });
    },

    //选择区县
    county(e) {
      let obj = {};
      obj = this.ruleForm.county.countyList.find((item) => {
        //这里的userList就是上面遍历的数据源
        return item.REGION_ID === e; //筛选出匹配数据
      });
      this.ruleForm.county.REGION_NAME = obj.REGION_NAME;
    },

    //表单提交按钮
    submitForm(formName) {
      console.log(formName);
      console.log(this.$refs[formName]);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
          console.log(
            "id",
            this.ruleForm.freight_id,
            "模板名称：",
            this.ruleForm.template_name,

            "计价方式：",
            this.ruleForm.pricing_method,

            "数量：",
            this.ruleForm.number,

            "起步价：",
            this.ruleForm.start_price,

            "增加数量：",
            this.ruleForm.increase_quantity,

            "加价：",
            this.ruleForm.increase_price,

            "是否启用：",
            this.ruleForm.is_open,

            "目的地：",
            this.ruleForm.province.REGION_NAME,
            this.ruleForm.city.REGION_NAME,
            this.ruleForm.county.REGION_NAME
          );
          //物流添加接口
          this.$request
            .setFreight({
              token: this.$storage.getLocal("token"), //登陆进来的tooken
              admin_type: this.$storage.getLocal("type"), //身份
              store_id: this.ruleForm.storeid, //是	int	门店id 超管传，商户传
              freight_id: this.ruleForm.freightid, //非	int	传id走编辑
              template_name: this.ruleForm.template_name, //是	string	模板名称
              pricing_method: this.ruleForm.pricing_method, //是	string	计价方式
              number: this.ruleForm.number, //是	int	数量
              start_price: this.ruleForm.start_price, //是	int	起步价
              increase_quantity: this.ruleForm.increase_quantity, //是	int	增加数量
              increase_price: this.ruleForm.increase_price, //是	int	加价
              province: this.ruleForm.province.REGION_NAME, //是	int	省
              city: this.ruleForm.city.REGION_NAME, //是	int	市
              county: this.ruleForm.county.REGION_NAME, //是	int	县区
              is_open: this.ruleForm.is_open, //非	string	是否禁用0启用1未启用
            })
            .then((res) => {
              console.log(res);
              if (res.code == 0) {
                this.$router.push({ path: "freight" });
              } else {
                alert("请求失败");
              }
            });
        } else {
          console.log("提交失败");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    //刷新页面按钮
    refresh() {
      location.reload();
    },
    //选中商户查询门店
    changeMerchant(business_id) {
      console.log(business_id);
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          business_id: business_id,
        })
        .then((res) => {
          console.log(res);
          this.ruleForm.storeList = res.data;
        });
    },
  },

  created() {
    let admin_type = this.$storage.getLocal("type");
    if (admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (admin_type == 2) {
      this.storeShow = true;
      this.$request
        .businessStoreList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          // business_id: business_id,
        })
        .then((res) => {
          console.log("帅气的我", res);
          this.ruleForm.storeList = res.data;
        });
    }

    // 请求商户列表
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.ruleForm.businessList = res.data;
    });

    //请求省份列表
    this.$request
      .regionSel({
        token: this.$storage.getLocal("token"), //登陆进来的tooken
        region_id: "",
      })
      .then((res) => {
        console.log(res);
        this.ruleForm.province.provinceList = res.data;
      });

    let edit = this.$route.query;
    console.log("编辑运费模板的默认值", edit);
    if (edit != "") {
      this.top1 = "";
      // this.merchantShow = false
      console.log(this);
      this.ruleForm.storeid = edit.storeid;
      this.ruleForm.freight_id = edit.freight_id; //非	int	传id走编辑
      this.ruleForm.template_name = edit.template_name; //是	string	模板名称
      this.ruleForm.pricing_method = edit.pricing_method; //是	string	计价方式
      this.ruleForm.number = edit.number; //是	int	数量
      this.ruleForm.start_price = edit.start_price; //是	int	起步价
      this.ruleForm.increase_quantity = edit.increase_quantity; //是	int	增加数量
      this.ruleForm.increase_price = edit.increase_price; //是	int	加价
      this.ruleForm.province.REGION_NAME = edit.province; //是	int	省
      this.ruleForm.city.REGION_NAME = edit.city; //是	int	市
      this.ruleForm.county.REGION_NAME = edit.county; //是	int	县区
      this.ruleForm.is_open = edit.is_open; //非	string	是否禁用0启用1未启用
    }
    // this.ruleForm = edit
    // this.ruleForm.province.REGION_NAME=edit.REGION_NAME
    this.$request
      .getUpStoreData({
        token: this.$storage.getLocal("token"),
        storeid: edit.store_id,
      })
      .then((r) => {
        console.log('商户id', r);
        if (r.code == 0) {
          //   console.log(r);
          this.ruleForm.business_id = r.data.pstoreid;
          this.$request
            .getStoreDataList({
              token: this.$storage.getLocal("token"),
              pstoreid: r.data.pstoreid,
              login_type: this.$storage.getLocal("type"),
            })
            .then((r) => {
              console.log(1233333333333, r);
              if (r.code == 0) {
                this.ruleForm.storeList = r.data;
                this.ruleForm.storeid = parseInt(edit.store_id);

                console.log(r);
              }
            });
        }
      });
    // console.log(this.storeid);
  },
  components: {
    frame,
  },
};
</script>

<style  scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  margin-top: 15px;
  width: 85%;
  margin-left: 200px;
  height: 40px;
  height: 40px;
  background-color: rgb(243, 243, 243);
}

.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.center {
  width: 40%;
  margin-left: 32%;
  margin-top: 50px;
  /* height: 500px; */
  /* border: 1px solid red; */
}
/* 上传图片的样式 */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>